import React from 'react';
import { Button } from 'react-bootstrap';
import { ReactComponent as ExpandIcon } from './_my_arrow_down.svg';
import { ReactComponent as CollapseIcon } from './_my_arrow_up.svg';

function ExpandCollapseButton(props) {

  return props.collapsed ? (
    <>
    <Button size="sm" variant="outline-secondary" className="mr-2 d-none d-sm-block d-md-none" onClick={props.expandCallback}><ExpandIcon className="action-icon"/></Button>
    <Button variant="outline-secondary" className="mr-2 d-none d-md-block" onClick={props.expandCallback}><ExpandIcon className="action-icon"/></Button>
    </>
  ) : (
    <>
    <Button size="sm" variant="outline-secondary" className="mr-2 d-none d-sm-block d-md-none" onClick={props.collapseCallback}><CollapseIcon className="action-icon"/></Button>
    <Button variant="outline-secondary" className="mr-2 d-none d-md-block" onClick={props.collapseCallback}><CollapseIcon className="action-icon"/></Button>
    </>
  );
}

export default ExpandCollapseButton;
