import React from 'react';
import './App.css';
import NumberInput from './NumberInput';
import { getTransactionsForUI } from './utils';
import { Badge } from 'react-bootstrap';
import { StickyTable, Row, Cell } from 'react-sticky-table';

function CalculationResults(props) {

  const data = props.data;
  if(data.persons.length === 0) {
    return "";
  }

  const transactions = getTransactionsForUI(data);
  const isTransactions = transactions.length > 0;

  return (
    <>
    <div className={props.showNetto ? 'd-block' : 'd-none'}>
    <h5 className="pl-5">Netto</h5><hr/>
    <div style={{width: '100%'}}>
      <StickyTable>
        <Row>
          <Cell className="table-cell calculation-card-element">Henkilö</Cell>
          <Cell className="table-cell calculation-card-element">Kulut</Cell>
          <Cell className="table-cell calculation-card-element">Loppusaldo</Cell>
        </Row>
    {data.persons.map(function(name, index) {
      return (
        <Row key={data.id + '-' + index}>
          <Cell className="table-cell calculation-card-element align-middle">
            <Badge variant="primary">{name}</Badge>
          </Cell>
          <Cell className="table-cell calculation-card-element table-cell-slim">
            <NumberInput readonly={true} i={index} array={data.calculated.net.expenses}/>
          </Cell>
          <Cell className="table-cell calculation-card-element table-cell-slim">
            <NumberInput readonly={true} i={index} array={data.calculated.net.saldo}/>
          </Cell>
        </Row>
      );
    })}
      </StickyTable>
    </div>
    </div>
    <h5 className={props.showNetto ? 'pl-5 pt-4' : 'pl-5'}>Maksettavaa</h5><hr/>
    <div style={{width: '100%'}} className={isTransactions ? 'd-block' : 'd-none'}>
      <StickyTable>
        <Row>
          <Cell className="table-cell calculation-card-element">Maksaja</Cell>
          <Cell className="table-cell calculation-card-element">Saaja</Cell>
          <Cell className="table-cell calculation-card-element">Määrä</Cell>
        </Row>
    {transactions.map(function(value, index) {
      return (
        <Row key={'tx-' + index}>
          <Cell className="table-cell calculation-card-element align-middle">
            <Badge variant="primary">{value[0]}</Badge>
          </Cell>
          <Cell className="table-cell calculation-card-element align-middle">
            <Badge variant="primary">{value[1]}</Badge>
          </Cell>
          <Cell className="table-cell calculation-card-element table-cell-slim">
            <NumberInput readonly={true} i={2} array={value}/>
          </Cell>
        </Row>
      );
    })}
      </StickyTable>
    </div>
    <div style={{width: '100%'}} className={!isTransactions ? 'd-block' : 'd-none'}>
      <p className="pl-4"><i>Ei maksettavaa</i></p>
    </div>
    </>
  );
}

export default CalculationResults;
