import React from 'react';
import './App.css';
import { Alert, Button } from 'react-bootstrap';

function ExpenseCalculationError(props) {

  return (
    <Alert variant="danger">
      <Alert.Heading>Oho! Laskelma käsittelyssä tapahtui virhe</Alert.Heading>
      <p>
        Voit poistaa laskelman
      </p>
       <Button onClick={() => props.deleteCallback(props.data)}>Poista</Button>
    </Alert>
  );
}

export default ExpenseCalculationError;
