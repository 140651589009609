import React, { useState } from 'react';
import './App.css';
import { getArrNumber, getArrValue,  evalInput } from './utils';
import { Form } from 'react-bootstrap';

function NumberInput(props) {

  const inputValue = getArrValue(props.array, props.i, props.j);
  const numberValue = evalInput(inputValue);

  const [input, setInput] = useState(inputValue);
  const [value, setValue] = useState(numberValue);
  const [validValue, setValidValue] = useState(true);
  const [showInput, setShowInput] = useState(false);

  function inputChange(event) {
    const value = event.target.value.trim();
    const inputValue = value.replace(/,/g, ".");
    const numberValue = evalInput(inputValue);
    const validValue = numberValue !== false;
    setInput(event.target.value);
    setValidValue(validValue);

    if(validValue) {
      setValue(numberValue);
      props.numberChangeCallback(props.i, props.j, inputValue);
    }
  }

  if(props.readonly) {
    // state is not used when read-only - value is directly from props
    const value = getArrNumber(props.array, props.i, props.j);
    return <Form.Control type="text" disabled className="calculation-card-element read-only-cell-input-element" value={value.toFixed(2)}/>
  }
  if(props.disabled) {
    return <Form.Control type="text" disabled className="calculation-card-element disabled-cell-input-element" />;
  }
  const CLASSES = "calculation-card-element cell-input-element " + (validValue ? "" : "red-text");
  return <Form.Control type="text" className={CLASSES} value={showInput ? input : value} onChange={inputChange} onFocus={() => setShowInput(true)} onBlur={() => setShowInput(!validValue)}/>
}

export default NumberInput;
