import React, { useState } from 'react';
import { Image, Button, Modal, Table } from 'react-bootstrap';
import Toggle from 'react-toggle';
import { ReactComponent as ExpandIcon } from './_my_arrow_down.svg';
import { ReactComponent as CollapseIcon } from './_my_arrow_up.svg';
import { ReactComponent as MenuIcon } from './_my_menu.svg';

function InstructionsModal(props) {

  const [openSection, setOpenSection] = useState('common');

  const td1Style = {width:'auto', whiteSpace: 'nowrap', borderTop: '1px solid #cccccc'}
  const td2Style = {width:'90%', borderTop: '1px solid #cccccc'}

  const sections = [
    {
      id: "common",
      title: "Yleistä",
      content: (
        <>
          <p>Laskelman tarkoitus on jakaa kulut tasan ryhmän kesken. Kirjaa henkilöiden maksamat kulut, niin
          ohjelma laskee henkilöiden väliset tasausmaksut. Voit tehdä useampia laskelmia, joten voit halutessasi
          säilyttää aiempia laskelmiasi. Uusin laskelma näytetään ylimpänä.</p>
          <p>Laskelma tallentuu automaattisesti kun teet siihen muutoksia. Kaikki tallentuvat vain selaimesi muistiin,
          eikä tietoja lähtetä minnekään.</p>
          <p><b>Palaute</b><br/>Palautetta ohjelmasta voi lähettää sähköpostilla osoitteeseen <code className="email">reissukulut@gmail.com</code></p>
          <p><small>Versio {process.env.REACT_APP_VERSION}</small></p>
        </>
      )
    },
    {
      id: "name-and-actions",
      title: "Laskelman nimi ja toiminnot",
      content: (
        <>
        <p>
          <b>Laskelman nimi</b>
        </p>
        <p>Anna laskelmalle kuvaava nimi niin erotat sen myöhemmin helposti muista laskelmista. Nimi ei kuitenkaan ole pakollinen.</p>
        <p>
          <b>Valikon toiminnot</b>
        </p>
        <Table style={{width:'100%'}}>
        <tbody>
          <tr>
            <td className="block" style={td1Style}>
              <Button size="sm" variant="outline-secondary"><CollapseIcon className="action-icon"/></Button>
            </td>
            <td className="block" style={td2Style}>Minimoi laskelma. Laskelman tiedot piilotetaan ja näytetään vain otsikkorivi.</td>
          </tr>
          <tr>
            <td className="block" style={td1Style}>
              <Button size="sm" variant="outline-secondary"><ExpandIcon className="action-icon"/></Button>
            </td>
            <td className="block" style={td2Style}>Näytä laskelma. Näytetään minimoidun laskelman tiedot.</td>
          </tr>
          <tr>
            <td className="block" style={td1Style}>Näytä / Minimoi laskelma</td>
            <td className="block" style={td2Style}>Näyttää tai minimoi laskelman</td>
          </tr>
          <tr>
            <td className="block" style={td1Style}>Näytä / Piilota nettotiedot</td>
            <td className="block" style={td2Style}>
              Näyttää tai piilottaa henkilöiden nettokulut. Nettokulut ovat henkilöiden maksamat kulut, joihin on lisätty henkilökohtaiset
              saatavat muilta ja vähennetty henkilökohtaiset velat muille. Loppusaldossa on lisäksi huomioitu yhteisten menojen osuus ja
              se kertoo jääkö henkilölle maksettavaa vai saatavia.
            </td>
          </tr>
          <tr>
            <td className="block" style={td1Style}>Poista laskelma</td>
            <td className="block" style={td2Style}>Poistaa laskelman ja kaikki sen tiedot. Laskelma poistetaan selaimesi muistista.</td>
          </tr>
        </tbody>
        </Table>
        </>
      )
    },
    {
      id: "shared-expenses-only",
      title: "Vain yhteiset kulut",
      content: (
        <>
        <p>Kun teet uuden laskelman, siihin kirjataan lähtökohtaisesti vain henkilöiden maksamat yhteiset kulut. Yhteiset kulut
        ovat kuluja, jotka jaetaan tasan kaikkien henkilöiden kesken.
        </p>
        <div className="p">
          <span><b>Vain yhteiset kulut</b></span>
          <label><Toggle className="custom-toggle" checked={true} readOnly/></label><br/>
          "Vain yhteiset kulut" on valittuna. Laskelmaan kirjataan vain henkilöiden maksamat yhteiset kulut.
        </div>
        <div className="p">
          <span><b>Vain yhteiset kulut</b></span>
          <label><Toggle className="custom-toggle" checked={false} readOnly/></label><br/>
          "Vain yhteiset kulut" ei ole valittuna. Laskelmaan voi kirjata henkilöiden maksamien yhteisten kulujen lisäksi henkilöiden
          välisiä henkilökohtaisia maksuja. Tämä tarkoittaa, että henkilö on maksanut toisen henkilön henkilökohtaisen kulun, eli
          lainannut toiselle henkilölle rahaa.
        </div>
        <p>Voit vaihtaa laskelman "Vain yhteiset kulut" tilaan tai pois siitä klikkaamalla liukukytkintä. Huomaa kuitenkin, että mikäli
        olet kirjannut laskelmalle henkilöiden välisiä maksuja ja vaihdat laskelman "Vain yhteiset kulut" -tilaan, kaikki syöttämäsi
        henkilöiden väliset kulut poistetaan. Sovellus kuitenkin pyytää tässä tilanteessa vielä vahvistamaan poiston.</p>
        <p>Lue lisää kohdasta <Button variant="link" onClick={() => setOpenSection('add-expenses')}>Kulujen kirjaaminen</Button></p>
        </>
      )
    },
    {
      id: "add-persons",
      title: "Henkilöiden lisääminen ja poistaminen",
      content: (
        <>
        <p>Laskelmaan pitää lisätä ainakin kaksi henkilöä, jotta laskelman tekeminen on mielekästä. Voit lisätä henkilön, kirjoittamalla
        henkilön nimen 'Lisää henkilö' otsikon alla olevaan tekstikentään ja painamalla [ENTER] tai Lisää-painiketta.</p>
        <p>Henkilön voi poistaa laskelmasta klikkaamalla henkilön nimeä Kulut-otsikon alla oleva taulukon ensimmäisestä sarakkeesta.
        Sovellus pyytää vahvistamaan henkikön poiston.</p>
        </>
      )
    },
    {
      id: "add-expenses",
      title: "Kulujen kirjaaminen",
      content: (
        <>
        <p>Kulut-otsikon alle kirjataan henkilöiden maksamat kulut. Riville henkilön nimen perään kirjataan tämän henkilön maksamat
        kulut. Näiden rivien jälkeen taulukossa Yhteensä-rivillä näkyy yhteisten menojen summa, ja Per hlö -rivillä tämä summa jaettuna
        henkilöiden lukumäärällä</p>
        <div className="p">
          <span><b>Vain yhteiset kulut</b></span>
          <label><Toggle className="custom-toggle" checked={true} readOnly/></label><br/>
          Tässä tapauksessa menojen kirjaaminen on yksinkertaista: jokaisen henkilön osalta kirjataan vain Yhteiset-sarakkeeseen henkilön
          maksamien yhteisten menojen summa. Yhteiset kulut ovat kuluja, jotka jaetaan tasan kaikkien henkilöiden kesken.
        </div>
        <div className="p">
          <span><b>Vain yhteiset kulut</b></span>
          <label><Toggle className="custom-toggle" checked={false} readOnly/></label><br/>
          Tässä tapauksessa yhteisten kulujen lisäksi voi kirjata henkilöiden välisiä henkilökohtaisia maksuja. Jokaisen henkilön osalta
          kirjataan Yhteiset-sarakkeeseen henkilön maksamien yhteisten menojen summa ja tämän perään riville henkilön maksamat muiden
          henkilöiden henkilökohtaiset kulut.
        </div>
        <div className="text-center">
          <Image src="expenses_example.png" className="responsive img-w-493"/>
        </div>
        <p>Yllä olevassa esimerkissä kolme henkilöä Kustaa, Oskar ja Kaarle haluavat tasata kulunsa. Kustaa on maksanut yhteisiä menoja
        10€ ja lisäksi hän maksoi Oskarin kuluja 10€ sekä Kaarlen kuluja 3€. Oskarin rahapussi unohtui kotiin, eikä hän maksanut mitään.
        Kaarle maksoi yhteisiä kuluja 5€ ja lisäksi Oskarin kuluja 7€. Harmaisiin kenttiin ei kirjata mitään.</p>
        </>
      )
    },
    {
      id: "netto",
      title: "Laskelman Netto-tiedot",
      content: (
        <>
        <p>Nettokulut ovat henkilöiden maksamat kulut, joihin on lisätty henkilökohtaiset saatavat muilta ja vähennetty henkilökohtaiset velat
        muille. Loppusaldossa on lisäksi huomioitu yhteisten menojen osuus ja se kertoo jääkö henkilölle maksettavaa vai saatavia.</p>
        <p>Saat nettotiedot näkyviin valikon <Button size="sm" variant="outline-secondary"><MenuIcon className="action-icon"/></Button> kautta.</p>
        <div className="text-center">
          <Image src="netto_example.png" className="responsive img-w-493"/>
        </div>
        <p>Yllä olevan esimerkin nettotiedot ovat tilanteesta jossa Kustaa on maksanut 10€ yhteisiä kuluja ja lisäksi 10€ Oskarin sekä 3€ Kaarlen
        kuluja, joten hänen nettokulunsa ovat 23€. Oskar ei ole maksanut mitään, mutta Kustaa ja Kaarle ovat maksaneet hänen kulujaan 17€, joten
        Oskarin nettokulut ovat -17€. Kaarle on maksanut 5€ yhteisiä kuluja sekä 7€ Oskarin kuluja ja lisäksi Kustaa on maksanut 3€ Kaarlen
        kuluja, joten Kaarlen nettokulut ovat 5€ + 7€  - 3€ = 9€.</p>
        <p>Loppusaldo jokaiselle muodostuu siten, että nettokuluista vähennetään yhteisten menojen osuus. Esimerkkitapauksessa yhteiset kulut
        ovat yhteensä 15€, eli 5€ per henkilö. Loppusaldo saadaan siis vähentämällä henkilön nettokuluista 5€.</p>
        <p>Positiivinen loppusaldo tarkoittaa, että henkilöllä on saatavia muilta, negatiivinen puolestaan sitä, että henkilöllä on maksettavaa
        muille.</p>
        </>
      )
    },
    {
      id: "results",
      title: "Maksettavaa",
      content: (
        <>
        <p>Maksettavaa-osiossa näkyy ohjelman laskemat tasausmaksut. Tasausmaksuja maksavat henkilöt, jotka ovat maksaneet yhteisiä kuluja
        vähemmän kuin osuutensa, tai joiden kuluja muut henkilöt ovat maksaneet. Tasausmaksujen suorittamisen jälkeen kaikki ovat maksaneet
        oman osuutensa yhteisistä kuluista ja henkilökohtaiset saatavat on kuitattu.</p>
        <div className="text-center">
          <Image src="payments_example.png" className="responsive img-w-493"/>
        </div>
        <p>Yllä olevassa esimerkissä tasausmaksuja maksaa vain Oskar, joka tulisi maksaa Kustaalle 18€ ja Kaarlelle 4€.</p>
        </>
      )
    }
  ];

  function toggleSection(sectionId) {
    setOpenSection( openSection === sectionId ? '' : sectionId);
  }

  return (
    <Modal show={props.show} onHide={props.closeModalCallback} dialogClassName="modal-90w">
      <Modal.Header className="my-modal-header" closeButton>
        <Modal.Title>Laskelman tekeminen</Modal.Title>
      </Modal.Header>
      <Modal.Body className="calculation-card-element">
      {sections.map((s) =>
        <div key={s.id}>
          <p className="mb-1 darker">
            <Button variant="link" onClick={() => toggleSection(s.id)} block className="darker-link-button">
              <b>{s.title}</b>
            </Button>
          </p>
          <div className={s.id === openSection ? 'd-block' : 'd-none'}>
            {s.content}
          </div>
        </div>
      )}
      </Modal.Body>

      <Modal.Footer className="my-modal-footer">
        <Button variant="primary" onClick={props.closeModalCallback}>Sulje</Button>
      </Modal.Footer>
    </Modal>
  );
}
export default InstructionsModal;
