import React, { useState } from 'react';
import './App.css';
import './react-toggle-style.css';
import StickyCalculationTable from './StickyCalculationTable';
import CalculationResults from './CalculationResults';
import ExpenseCalculationError from './ExpenseCalculationError';
import ConfirmModal from './ConfirmModal';
import ExpandCollapseButton from './ExpandCollapseButton';
import { hasPersonToPersonPayments, cloneCalculation, calculationAddPerson, calculationRemovePerson, calculationSimplify, migrateCalculationData } from './utils';
import { Alert, Button, ButtonToolbar, Dropdown, Card, Form, Container, Col, InputGroup, Row } from 'react-bootstrap';
import Toggle from 'react-toggle';
import { ReactComponent as MenuIcon } from './_my_menu.svg';

class ExpenseCalculation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      errorOccurred: false,
    };
  }
  componentDidCatch(error, info) {
    this.setState({ errorOccurred: true })
  }

  render() {
    const data = migrateCalculationData(this.props.data);
    if(this.state.errorOccurred) {
      return <ExpenseCalculationError data={data} deleteCallback={this.props.deleteCallback}/>
    }
    return <ExpenseCalculationCard
      data={data}
      persistCallback={this.props.persistCallback}
      deleteCallback={this.props.deleteCallback}/>
  }
}

function ExpenseCalculationCard(props) {

  const [nameToAdd, setNameToAdd] = useState(['', false]);
  const [simplifyModal, setSimplifyModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [showNetto, setShowNetto] = useState(false);

  function changed(data) {
    props.persistCallback(data);
  }

  function titleChanged(event) {
    const data = cloneCalculation(props.data);
    data.title = event.target.value;
    changed(data);
  }

  function addPerson(name) {
    const data = cloneCalculation(props.data);
    calculationAddPerson(data, name);
    changed(data);
  }

  function removePerson(index) {
    const data = cloneCalculation(props.data);
    calculationRemovePerson(data, index);
    changed(data);
  }

  function nameExists(name) {
    return props.data.persons.some(n => n.toLowerCase() === name.toLowerCase());
  }

  function personNameChange(event) {
    var newName = event.target.value.trim();
    if(newName.trim() === "") {
      setNameToAdd(['', false]);
      return;
    }
    if(nameExists(newName)) {
      setNameToAdd([newName, true]);
      return;
    }
    setNameToAdd([newName, false]);
  }

  function addPersonClick(event) {
    if(event.key !== 'Enter' && event.type !== 'click') {
      return;
    }
    var newName = nameToAdd[0];
    var nameExists = nameToAdd[1];
    if(nameExists || newName.trim() === "") {
      return;
    }
    setNameToAdd(['', false]);
    addPerson(newName);
  }

  function collapse() {
    const data = cloneCalculation(props.data);
    data.collapsed = true;
    changed(data);
  }

  function expand() {
    const data = cloneCalculation(props.data);
    data.collapsed = false;
    changed(data);
  }

  function toggleFullCalculation() {
    const changeToSimple = !props.data.simple;
    if(changeToSimple && hasPersonToPersonPayments(props.data)) {
      setSimplifyModal(true);

    } else {
      const data = cloneCalculation(props.data);
      data.simple = !data.simple;
      changed(data);
    }
  }

  function toggleNetto() {
    setShowNetto(!showNetto);
  }

  function confirmSimplification() {
    const data = cloneCalculation(props.data);
    calculationSimplify(data);
    changed(data);
    setSimplifyModal(false);
  }

  function clickDelete() {
    const data = props.data;
    if(data.created) {
      setDeleteModal(true);
    } else {
      props.deleteCallback(data);
    }
  }

  const data = props.data;
  const NAME_INPUT_CLASSES = "calculation-card-element " + (nameToAdd[1] ? "red-text " : "");

  return (
    <Card className="calculation-card" id={'calc-' + data.id}>
      <ConfirmModal
        show={simplifyModal}
        title="Vain yhteiset kulut"
        message="Haluatko yksinkertaistaa laskelman? Laskelmaa jäävät vain yhteiset kulut ja henkilöiden väliset kulut poistetaan."
        buttonNo="En"
        buttonYes="Kyllä, poistetaan"
        closeModalCallback={() => setSimplifyModal(false)}
        confirmedCallback={confirmSimplification}
        />
      <ConfirmModal
        show={deleteModal}
        title="Laskelman poisto"
        message="Haluatko poistaa laskelman?"
        buttonNo="En"
        buttonYes="Kyllä, poistetaan"
        closeModalCallback={() => setDeleteModal(false)}
        confirmedCallback={() => props.deleteCallback(data)}
        />
      <Card.Header>
        <Row>
          <Col xs="9" lg="10">
            <Form.Control type="text" placeholder="Laskelman nimi" value={data.title} className="calculation-card-element d-block d-md-none" onChange={titleChanged}/>
            <Form.Control type="text" placeholder="Laskelman nimi" value={data.title} size="lg" className="calculation-card-element d-none d-md-block" onChange={titleChanged}/>
          </Col>
          <Col xs="3" lg="2" className="pt-md-1 pl-0">
            <ButtonToolbar className="justify-content-end">
              <ExpandCollapseButton collapsed={data.collapsed} expandCallback={expand} collapseCallback={collapse}/>
              <Dropdown>
                <Dropdown.Toggle variant="outline-secondary"><MenuIcon className="action-icon"/></Dropdown.Toggle>
                <Dropdown.Menu alignRight={true}>
                  <Dropdown.Item className="my-dropdown" onClick={data.collapsed ? expand : collapse}>Näytä / Minimoi laskelma</Dropdown.Item>
                  <Dropdown.Item className="my-dropdown" onClick={toggleNetto}>Näytä / Piilota nettotiedot</Dropdown.Item>
                  <Dropdown.Divider/>
                  <Dropdown.Item className="my-dropdown" onClick={clickDelete}>Poista laskelma</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </ButtonToolbar>
          </Col>
        </Row>
      </Card.Header>
      <Card.Body className={data.collapsed ? "d-none" : ""}>
          <Container>
          <Row>
            <Col xs={{ span:12, order: 2 }} md="7">
              <h5 className="pl-5">Lisää henkilö</h5><hr/>
              <Alert variant="success" show={data.persons.length < 2}>Kirjoita henkilön nimi alle ja paina 'Lisää' (tai <span className="monospace">[ENTER]</span>)</Alert>
              <InputGroup>
                <Form.Control type="text" placeholder="Nimi" className={NAME_INPUT_CLASSES} onKeyUp={addPersonClick} autoComplete="off" value={nameToAdd[0]} onChange={personNameChange}/>
                <InputGroup.Append>
                  <Button variant="primary" onClick={addPersonClick}>Lisää</Button>
                </InputGroup.Append>
              </InputGroup>
            </Col>
            <Col xs={{ span:12, order: 1 }} md={{ span:5, order: 3 }} className="pt-1 pb-2 text-right">
              <span>Vain yhteiset kulut</span>
              <label>
                <Toggle className="custom-toggle" checked={data.simple} onChange={toggleFullCalculation} />
              </label>
            </Col>
          </Row>
          <Row>
            <Col xs="12" md={data.simple ? "5" : "12"} className="pt-4">
              <StickyCalculationTable data={data} removePersonCallback={removePerson} dataChangedCallback={changed}/>
            </Col>
            <Col xs="12" md={data.simple ? "7" : "12"} className="pt-4">
              <CalculationResults data={data} showNetto={showNetto}/>
            </Col>
          </Row>
        </Container>
      </Card.Body>
    </Card>
  );
}

export default ExpenseCalculation;
