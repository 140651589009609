import React, { useState, useEffect } from 'react';
import './App.css';
import { ButtonToolbar, Dropdown, Col, Row, Container } from 'react-bootstrap';
import { ReactComponent as MenuIcon } from './_my_menu.svg';

function Header(props) {

  const [shrink, setShrink] = useState(false);

  useEffect(() => {
    function handleScroll(event) {
      const noShrink = (shrink && window.pageYOffset === 0) || (!shrink && window.pageYOffset < 50);
      setShrink(!noShrink);
    }
    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [shrink]); // run effect only if value of shrink has changed

  const CLASSES = "page-header " + (shrink ? "page-header-shrink " : "");

  return (
    <div id="header" className="sticky-top">
      <Container fluid="true" className={CLASSES}>
        <Row  className="pt-1 pl-0">
          <Col xs="2" md="1"></Col>
          <Col xs="8" md="10" className="page-header-title">
          { shrink ? (
            <h3>reissukulut<small className="text-muted">.fi</small></h3>
          ) : (
            <h2>reissukulut<small className="text-muted">.fi</small></h2>
          )}
          </Col>
          <Col xs="2" md="1" className="header-menu-button">
            <ButtonToolbar className="justify-content-end">
              <Dropdown>
                <Dropdown.Toggle variant="outline-secondary"><MenuIcon className="action-icon"/></Dropdown.Toggle>
                <Dropdown.Menu alignRight={true}>
                  <Dropdown.Item className="my-dropdown" onClick={props.addNewCalculationCallback}>Uusi laskelma</Dropdown.Item>
                  <Dropdown.Divider/>
                  <Dropdown.Item className="my-dropdown" onClick={props.showInstructionsCallback}>Ohjeet</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </ButtonToolbar>
          </Col>
        </Row>
      </Container>
      <Container fluid="true" className="dark-bg">
        <Row>
          <Col xs="12">
          <hr className="header-divider"/>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default Header;
