import React, { useState, useEffect } from 'react';
import './App.css';
import { Container, Col, Row } from 'react-bootstrap';
import Header from './Header';
import InstructionsModal from './InstructionsModal';
import ExpenseCalculation from './ExpenseCalculation';
import { getNewCalculationObject } from './utils';

export const LOCALSTORAGE_KEY = "reissukulut.fi";

function App(props) {

  const [calculations, setCalculations] = useState([]);
  const [showInstructions, setShowInstructions] = useState(false);

  useEffect(() => {
    loadAllFromStorage();
  }, []);

  function loadAllFromStorage() {
    const data = localStorage.getItem(LOCALSTORAGE_KEY);
    const items = data ? JSON.parse(data) : [];

    if(items.length === 0) {
      items.push(getNewCalculationObject())
    }
    setCalculations(items);
  }

  function addNewCalculation() {
    const newCalculations = [
      getNewCalculationObject(),
      ...calculations,
    ];
    setCalculations(newCalculations);
  }

  function persistCalculation(calculation) {
    if(!calculation.created) {
      calculation.created = (new Date()).toISOString();
    }
    calculation.modified = (new Date()).toISOString();
    const newCalculations = calculations.map(item => item.id === calculation.id ? calculation : item);
    setCalculations(newCalculations);
    localStorage.setItem(LOCALSTORAGE_KEY, JSON.stringify(newCalculations));
  }

  function deleteCalculation(calculation) {
    const newCalculations = calculations.filter(function(value, index, arr) {
      return value.id !== calculation.id;
    });
    setCalculations(newCalculations);
    localStorage.setItem(LOCALSTORAGE_KEY, JSON.stringify(newCalculations));
  }

  function toCalculation(data) {
    return  (
      <Row key={data.id} className="pt-5">
        <Col>
          <ExpenseCalculation data={data}
            persistCallback={persistCalculation}
            deleteCallback={deleteCalculation}
          />
        </Col>
      </Row>
    );
  }

  return (
    <div className="App">
      <Header addNewCalculationCallback={addNewCalculation} showInstructionsCallback={() => setShowInstructions(true)}/>
      <Container className="calculation-card-container">
        {calculations.map(calculation => toCalculation(calculation))}
      </Container>
      <InstructionsModal show={showInstructions} closeModalCallback={() => setShowInstructions(false)}/>
    </div>
  );
}

export default App;
