
const uuidv4 = require('uuid/v4');
const cloneDeep = require('clone-deep');
const math = require('mathjs');

export function evalInput(expr) {
  if(!expr) { // empty string
    return 0;
  }
  try {
    const value = math.evaluate(expr);
    if(!isFinite(value)) {
      return false;
    }
    return value === undefined ? 0 : value;
  } catch (err) {
    return false;
  }
}
export function cloneCalculation(data) {
  return cloneDeep(data);
}

export function getNewCalculationObject() {
  const data = {};
  data.id = uuidv4();
  data.simple = true;
  data.persons = [];
  data.input = [];
  data.numbers = [];
  data.title = '';
  return data;
}

export function calculationAddPerson(data, name) {
  data.persons.push(name);
  data.input.push([]);
  data.numbers.push([]);
  data.persons.forEach(function(v,i,arr) {
    while(data.numbers[i].length <= data.persons.length) {
      data.input[i].push(0);
      data.numbers[i].push(0);
    }
  });
  calculateResults(data);
}

export function calculationRemovePerson(data, index) {
  data.persons.splice(index, 1);
  data.input.splice(index, 1);
  data.numbers.splice(index, 1);
  data.numbers.forEach(function(v, i, arr) {
    data.input[i].splice(index+1, 1);
    data.numbers[i].splice(index+1, 1);
  });
  calculateResults(data);
}

export function calculationSimplify(data) {
  removePersonToPersonPayments(data);
  calculateResults(data);
  data.simple = true;
}

export function getNumber(value) {
  if(isNaN(value)) {
    return 0;
  }
  return Number(value);
}

export function getArrNumber(arr, i, j) {
  return getNumber(getArrValue(arr, i, j));
}

export function getArrValue(arr, i, j) {
  var value = arr[i];
  if(Array.isArray(value)) {
    value = value[j];
  }
  return value;
}

export function calculateResults(data) {
  data.input.forEach(function(v1,i1,arr1) {
    data.input[i1].forEach(function(v2,i2,arr2) {
      data.numbers[i1][i2] = evalInput(data.input[i1][i2]);
    });
  });

  const rowTotals = [];
  const colTotals = [];
  data.persons.forEach(function(v1,i1,arr1) {
    var num = getArrNumber(data.numbers, i1, 0);
    const col = getArrNumber(colTotals, 0) + num;
    colTotals[0] = col;
    var rowSum = 0;
    data.persons.forEach(function(v2,i2,arr1) {
      const num = getArrNumber(data.numbers, i1, i2+1);
      rowSum += num;
      colTotals[i2+1] = getArrNumber(colTotals, i2+1) + num;
    });
    rowTotals[i1] = rowSum;
  });

  const sharedPerPerson = [colTotals[0]/data.persons.length];

  const netExpenses = [];
  const netSaldo = [];
  data.persons.forEach(function(v,i,arr) {
    netExpenses[i] = getArrNumber(data.numbers, i, 0) + rowTotals[i] - colTotals[i + 1];
    netSaldo[i] = netExpenses[i] - sharedPerPerson;
  });

  data.calculated = {}
  data.calculated.sums = {}
  data.calculated.sums.row = rowTotals;
  data.calculated.sums.col = colTotals;
  data.calculated.sums.sharedPerPerson = sharedPerPerson;
  data.calculated.net = {}
  data.calculated.net.expenses = netExpenses;
  data.calculated.net.saldo = netSaldo;
  data.calculated.transactions = getTransactions(netSaldo);
}

export function getTransactions(netSaldoOrig) {
  const transactions = [];
  const netSaldo = [...netSaldoOrig];

  const sum = netSaldo.reduce((a,b) => a + b, 0);
  if(!isZero(sum)) {
    return undefined;
  }

  // initialize transaction array
  var i = 0;
  for(i = 0; i < netSaldo.length; i++) {
    if(isZero(netSaldo[i]) || netSaldo[i] >= 0) {
      // person saldo is zero or positive => doesn't need to pay => empty array
      transactions[i] = [];
    } else {
      // perso saldo is negative => needs to pay => initialize full array
      transactions[i] = new Array(netSaldo.length).fill(0);
    }
  }

  var stop = false;
  while(!allZero(netSaldo) && !stop) {
    for(i = 0; i < netSaldo.length; i++) {
      if(isZero(netSaldo[i]) || netSaldo[i] >= 0) {
        // person saldo is zero or positive
        continue;
      }
      // perso saldo is negative
      for(var j = i+1; j < netSaldo.length; j++) {
        if(netSaldo[i].toFixed(2) === "-" + netSaldo[j].toFixed(2)) {
          // two persons have oppisite saldo
          transactions[i][j] = netSaldo[j];
          netSaldo[i] = 0;
          netSaldo[j] = 0;
          break;
        }
      }
    }

    const min_index = arrayIndexSmallestNegative(netSaldo);
    if(min_index >= 0) {
      // handle person with smallest payment
      const gte_index = arrayIndexSmallestGTE(netSaldo, -netSaldo[min_index]);
      if(gte_index >= 0) {
        // can be handled with a single payment
        transactions[min_index][gte_index] = -netSaldo[min_index];
        netSaldo[gte_index] = netSaldo[gte_index] + netSaldo[min_index];
        netSaldo[min_index] = 0;

      } else {
        // requires more than one payment
        while(!isZero(netSaldo[min_index])) {
          const max_index = arrayIndexGreatestPositive(netSaldo);
          if(max_index === -1) {
            // no positive saldos remaining (in 2 digit precision)
            stop = true;
            break;
          }
          const to_balance = Math.min(-netSaldo[min_index], netSaldo[max_index]);
          transactions[min_index][max_index] = to_balance;
          netSaldo[min_index] = netSaldo[min_index] + to_balance;
          netSaldo[max_index] = netSaldo[max_index] - to_balance;
        }
      }
    } else {
      // no negative saldos remaining (in 2 digit precision)
      stop = true;
    }
  }

  return transactions;
}

export function getTransactionsForUI(data) {
  const transactions = data.calculated.transactions;
  const transactionsUI = [];
  transactions.forEach((v,i,arr) => {
    if(v.length === 0) return;
    v.forEach((v2,i2,arr2) => {
      if(v2 === 0) return;
      transactionsUI.push([data.persons[i], data.persons[i2], v2]);
    });
  });
  return transactionsUI;
}

export function isZero(num) {
  const fixed = num.toFixed(2);
  return (fixed === "0.00") || (fixed === "-0.00");
}

export function allZero(arr) {
  return arr.every(e => Array.isArray(e) ? allZero(e) : isZero(e));
}

export function arrayIndexSmallestNegative(arr) {
  var min_i = -1;
  var min = Number.MIN_SAFE_INTEGER;
  arr.forEach((v,i,a) => {
    if(v > min && v < 0 && !isZero(v)) {
      min_i = i;
      min = v;
    }
  });
  return min_i;
}

export function arrayIndexSmallestGTE(arr, num) {
  var min_i = -1;
  var min = Number.MAX_SAFE_INTEGER;
  arr.forEach((v,i,a) => {
    if((v > num && v < min) || v.toFixed(2) === num.toFixed(2)) {
      min_i = i;
      min = v;
    }
  });
  return min_i;
}

export function arrayIndexGreatestPositive(arr) {
  var max_i = -1;
  var max = Number.MIN_SAFE_INTEGER;
  arr.forEach((v,i,a) => {
    if(v > max && v > 0 && !isZero(v)) {
      max_i = i;
      max = v;
    }
  });
  return max_i;
}

export function hasPersonToPersonPayments(data) {
  var found = false;
  data.persons.forEach(function(v1,i1,arr1) {
    data.persons.forEach(function(v2,i2,arr1) {
      if(getArrNumber(data.numbers, i1, i2+1) > 0) {
        found = true;
      }
    });
  });
  return found;
}

export function removePersonToPersonPayments(data) {
  data.persons.forEach(function(v1,i1,arr1) {
    data.persons.forEach(function(v2,i2,arr1) {
      data.input[i1][i2+1] = 0;
      data.numbers[i1][i2+1] = 0;
    });
  });
}

/**
 * Migrates older calculation data to latest format
 */
export function migrateCalculationData(data) {
  if(!('input' in data)) {
    data['input'] = cloneDeep(data.numbers);
  }
  return data;
}
