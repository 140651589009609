import React from 'react';
import { Modal, Button } from 'react-bootstrap';

function ConfirmModal(props) {
  return (
    <Modal show={props.show} onHide={props.closeModalCallback}>
      <Modal.Header className="my-modal-header" closeButton>
        <Modal.Title>{props.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body className="calculation-card-element">
        <p>{props.message}</p>
      </Modal.Body>

      <Modal.Footer className="my-modal-footer">
        <Button variant="primary" onClick={props.closeModalCallback}>{props.buttonNo}</Button>
        <Button variant="danger" onClick={props.confirmedCallback}>{props.buttonYes}</Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ConfirmModal;
