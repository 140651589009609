import React, { useState } from 'react';
import './App.css';
import NumberInput from './NumberInput';
import ConfirmModal from './ConfirmModal';
import * as utils from './utils';
import { Alert, Badge } from 'react-bootstrap';
import { StickyTable, Row, Cell } from 'react-sticky-table';

function StickyCalculationTable(props) {

  const [modal, setModal] = useState([false, -1]);

  function verifyDelete(index) {
    setModal([true, index]);
  }

  function deletePerson() {
    const toDelete = modal[1];
    props.removePersonCallback(toDelete);
    closeModal();
  }

  function closeModal() {
    setModal([false, -1]);
  }

  function numberChange(i, j, value) {
    const data = utils.cloneCalculation(props.data);
    data.input[i][j] = value;
    data.numbers[i][j] = utils.evalInput(value);
    utils.calculateResults(data);
    props.dataChangedCallback(data);
  }

  const data = props.data;
  if(data.persons.length === 0) {
    return "";
  }

  return (
    <><h5 className="pl-5">Kulut</h5><hr/>
      <Alert variant="success" show={(data.persons.length >= 2) && utils.allZero(data.numbers)}>Täytä henkilöiden maksamat kulut alla olevaan taulukkoon. Lisätietoja ohjesivulta.</Alert>
      <div style={{width: '100%'}}>
        <ConfirmModal
          show={modal[0]}
          title="Poista henkilö"
          message=<span>Haluatko poistaa henkilön <b>{data.persons[modal[1]]}</b> ja hänelle annetut tiedot?</span>
          buttonNo="En"
          buttonYes="Kyllä, poistetaan"
          closeModalCallback={closeModal}
          confirmedCallback={deletePerson}
          />
        <StickyTable>
          <Row>
            <Cell className="table-cell calculation-card-element">Henkilö</Cell>
            <Cell className="table-cell calculation-card-element table-cell-slim">Yhteiset</Cell>
            {!data.simple && data.persons.map(function(name, index) {
              return (
                <Cell key={'header-' + index} className="table-cell calculation-card-element table-cell-slim">
                  <Badge variant="primary">{name}</Badge>
                </Cell>
                );
            })}
          </Row>
          {data.persons.map(function(name, index) {
            return (<Row key={name}>
              <Cell className="table-cell calculation-card-element align-middle">
                <Badge variant="primary" onClick={() => verifyDelete(index)} className="name-badge-delete">
                  {name}
                </Badge>
              </Cell>
              <Cell className="table-cell calculation-card-element table-cell-slim">
                <NumberInput i={index} j="0" array={data.input} numberChangeCallback={numberChange}/>
              </Cell>
              {!data.simple && data.persons.map(function(name2, index2) {
                const id = name + '_' + name2;
                return (
                  <Cell key={id} className="table-cell calculation-card-element table-cell-slim">
                    <NumberInput disabled={name===name2} i={index} j={index2+1} array={data.input} numberChangeCallback={numberChange}/>
                  </Cell>
                );
              })}
            </Row>);
          })}
          <Row>
            <Cell className="table-cell calculation-card-element align-middle">Yhteensä</Cell>
            <Cell className="table-cell calculation-card-element table-cell-slim">
              <NumberInput readonly={true} i={0} array={data.calculated.sums.col}/>
            </Cell>
          </Row>
          <Row>
            <Cell className="table-cell calculation-card-element align-middle">Per hlö</Cell>
            <Cell className="table-cell calculation-card-element table-cell-slim">
              <NumberInput readonly={true} i={0} array={data.calculated.sums.sharedPerPerson}/>
            </Cell>
          </Row>
        </StickyTable>
      </div>
    </>
  );
}

export default StickyCalculationTable;
